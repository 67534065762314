import { useQuery } from '@tanstack/react-query'
import useAgency from 'agency/contexts/AgencyContext'
import { Agency } from 'agency/types/agency'
import { Agent } from 'agency/types/agents'
import httpClient from 'utils/http-client'

const AGENT_KEY = ['agent']
const AGENTS_KEY = ['agents']

const getAgentsKey = (agencyId: string | null) => [...AGENTS_KEY, agencyId]

type AgentShow = {
  first_name: string
  last_name: string
  email: string
  id: string
  agencies: Agency[]
}

const THIRTY_MINUTES = 1000 * 60 * 30

export const useAgent = () => {
  const { setAgencies } = useAgency()

  return useQuery<Agent>({
    queryKey: AGENT_KEY,
    queryFn: () =>
      httpClient<AgentShow>('/agent').then((payload) => {
        setAgencies(payload.agencies)
        return {
          firstName: payload.first_name,
          lastName: payload.last_name,
          email: payload.email,
          id: payload.id,
        }
      }),
    staleTime: THIRTY_MINUTES,
  })
}

type AgentIndexResponse = {
  id: string
  first_name: string
  last_name: string
  email: string
}

export const useAgents = () => {
  const { currentAgencyId } = useAgency()

  return useQuery<Agent[]>({
    queryKey: getAgentsKey(currentAgencyId),
    queryFn: () =>
      httpClient<AgentIndexResponse[]>(`/agencies/${currentAgencyId}/agents`).then((agents) =>
        agents.map(({ id, first_name, last_name, email }) => ({
          id,
          firstName: first_name,
          lastName: last_name,
          email,
        }))
      ),
    initialData: [],
    enabled: !!currentAgencyId,
  })
}
