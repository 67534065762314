import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import i18next from 'i18next'
import { useEmployee } from 'agency/queries/employees'
import useAgency from 'agency/contexts/AgencyContext'
import { DocumentGroup } from 'agency/types/document_groups'
import EmployeeProfileHeader from 'agency/components/agency_employees/AgencyEmployeeShow/EmployeeProfileHeader'
import DocumentGroupList from 'agency/components/agency_employees/AgencyEmployeeShow/DocumentGroupList'
import DocumentPreview from 'agency/components/agency_employees/AgencyEmployeeShow/DocumentPreview'
import DocumentUpload from 'agency/components/agency_employees/AgencyEmployeeShow/DocumentUpload'
import Background from 'agency/components/Background'

const EmployeeProfile = () => {
  const { currentAgencyId } = useAgency()
  const { agencyEmployeeId } = useParams()
  const navigate = useNavigate()
  const [displayDocumentPreviewForDocumentId, setDisplayDocumentPreviewForDocumentId] = useState<string>('')
  const [documentGroupsUploadChoices, setDocumentGroupsUploadChoices] = useState<DocumentGroup[]>([])

  const { data: employee, error } = useEmployee(agencyEmployeeId)

  useEffect(() => {
    if (!error) return

    toast.error(i18next.t('agency.employee_profile.not_found'))
    if (window.user_id_at_spa_load) navigate(`/agencies/${currentAgencyId}/agency_employees`)
  }, [error])

  return agencyEmployeeId && employee ? (
    <div className="h-full flex flex-col">
      {displayDocumentPreviewForDocumentId ? (
        <DocumentPreview
          documentId={displayDocumentPreviewForDocumentId}
          agencyEmployeeId={agencyEmployeeId}
          closePreview={() => setDisplayDocumentPreviewForDocumentId('')}
        />
      ) : null}

      {documentGroupsUploadChoices.length > 0 ? (
        <DocumentUpload
          documentGroups={documentGroupsUploadChoices}
          agencyEmployeeId={agencyEmployeeId}
          closePreview={() => setDocumentGroupsUploadChoices([])}
        />
      ) : null}
      <EmployeeProfileHeader employee={employee} />
      <Background>
        <DocumentGroupList
          documentGroups={employee.document_groups}
          agencyEmployeeId={agencyEmployeeId}
          setDisplayDocumentPreviewForDocumentId={setDisplayDocumentPreviewForDocumentId}
          setDocumentGroupsUploadChoices={setDocumentGroupsUploadChoices}
        />
      </Background>
    </div>
  ) : null
}

export default EmployeeProfile
