import React, { useState } from 'react'
import i18next from 'i18next'

import Icon from 'components/Icon'
import Dropdown from 'components/Dropdown'
import { EmployeeWithInvitationStatus } from 'agency/types/employees'
import { useRemoveEmployee } from 'agency/queries/employees'
import DocumentRequestModal from 'agency/components/DocumentRequestModal'

type InvitedEmployeeActionButtonProps = {
  employee: EmployeeWithInvitationStatus
}

const InvitedEmployeeActionButton = ({ employee }: InvitedEmployeeActionButtonProps) => {
  const [isDocumentRequestModalOpen, setIsDocumentRequestModalOpen] = useState(false)

  const { mutate: removeEmployee } = useRemoveEmployee()

  return (
    <div>
      <Dropdown
        className="py-2"
        trigger={<Icon name="more_vert" />}
        label={i18next.t('agency.home.employee_list.modify_invitation_for', {
          name: `${employee.last_name} ${employee.first_name}`,
        })}
      >
        <Dropdown.Item onClick={() => setIsDocumentRequestModalOpen(true)}>
          <button type="button">
            <Icon name="edit" className="mr-4" />
            {i18next.t('agency.home.employee_list.modify_requested_documents')}
          </button>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => removeEmployee(employee.agency_employee_id)}>
          <button type="button" className="text-error">
            <Icon name="delete" uiStyle="error" className="mr-4" />
            {i18next.t('agency.home.employee_list.delete_invitation')}
          </button>
        </Dropdown.Item>
      </Dropdown>

      {isDocumentRequestModalOpen ? (
        <DocumentRequestModal
          closeModal={() => setIsDocumentRequestModalOpen(false)}
          agencyEmployeeId={employee.agency_employee_id}
          existingDocumentGroupKindSlugs={employee.document_groups_slugs}
        />
      ) : null}
    </div>
  )
}

export default InvitedEmployeeActionButton
