import React from 'react'
import i18next from 'i18next'
import toast from 'react-hot-toast'

import { DocumentGroupKind } from 'agency/types/document_groups'
import DocumentGroupKindsSelect from 'agency/components/DocumentGroupKindsSelect'
import Divider from 'components/Divider'
import Text from 'components/Text'
import Input from 'components/Input'
import Modal from 'agency/components/Modal'
import { Trade } from 'agency/types/trades'

export type SubmitTradePayload = Pick<Trade, 'name' | 'documentGroupsSlugs'>

type TradeRequestModalProps = {
  closeModal: () => void
  trade?: Trade
  onSubmit: (trade: SubmitTradePayload) => void
}

const getDefaultSelectedDocumentKinds = (trade: Trade | undefined): DocumentGroupKind[] => {
  if (!trade) return []

  return window.document_group_kinds.filter((documentKind: DocumentGroupKind) =>
    trade.documentGroupsSlugs.includes(documentKind.slug)
  )
}

const TradeRequestModal = ({ closeModal, onSubmit, trade }: TradeRequestModalProps) => {
  const [tradeName, setTradeName] = React.useState(trade?.name ?? '')
  const [selectedDocumentKinds, setSelectedDocumentKinds] = React.useState<DocumentGroupKind[]>(
    getDefaultSelectedDocumentKinds(trade)
  )

  const [errors, setErrors] = React.useState({ tradeName: '' })

  const handleOnSubmit = () => {
    const documentGroupsSlugs = selectedDocumentKinds.map((documentKind) => documentKind.slug)

    if (documentGroupsSlugs.length === 0) {
      toast.error(i18next.t('agency.settings.trades.error_no_document_group'))
      return
    }

    if (!tradeName) {
      setErrors({ ...errors, tradeName: i18next.t('agency.settings.trades.error_no_name') })
      toast.error(i18next.t('agency.settings.trades.error_no_name'))
      return
    }

    onSubmit({
      name: tradeName,
      documentGroupsSlugs,
    })
  }

  return (
    <Modal onRequestClose={closeModal}>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          handleOnSubmit()
        }}
        className="h-full"
      >
        <div className="flex flex-col justify-between h-full">
          <div className="overflow-y-auto px-6 pt-6">
            <Text type="title" className="mb-2">
              {i18next.t(trade?.id ? 'agency.settings.trades.update_title' : 'agency.settings.trades.add', {
                tradeName: trade?.name,
              })}
            </Text>
            <Divider className="mb-6" />
            <Input
              className="mb-6"
              label={i18next.t('agency.settings.trades.name_label')}
              name={i18next.t('agency.settings.trades.name_label')}
              onChange={(newName) => {
                setTradeName(newName)
                setErrors({ tradeName: '' })
              }}
              placeholder={i18next.t('agency.settings.trades.name_placeholder')}
              error={errors.tradeName}
              value={tradeName}
            />

            <Text type="text" uiStyle="bold" className="mb-1.5">
              {i18next.t('agency.settings.trades.document_groups_slugs')}
            </Text>
            <DocumentGroupKindsSelect
              selectedDocumentKinds={selectedDocumentKinds}
              setSelectedDocumentKinds={setSelectedDocumentKinds}
              removableDefault
            />
          </div>
          <Modal.Actions
            submitText={i18next.t(trade?.id ? 'agency.settings.trades.update' : 'agency.settings.trades.add')}
          />
        </div>
      </form>
    </Modal>
  )
}

export default TradeRequestModal
