import React from 'react'
import i18next from 'i18next'

import { DocumentGroup, DocumentGroupKind } from 'agency/types/document_groups'
import DocumentGroupKindsSelect from 'agency/components/DocumentGroupKindsSelect'
import Icon from 'components/Icon'
import Text from 'components/Text'
import Modal from 'agency/components/Modal'
import CirclesIllustration from 'agency/components/CirclesIllustration'
import { useAddDocumentGroupRequests } from 'agency/queries/documents'
import { useUpdateDocumentGroupsForEmployee } from 'agency/queries/employeesStateActions'

type DocumentRequestModalProps = {
  closeModal: () => void
  agencyEmployeeId: string
  existingDocumentGroupKindSlugs: string[]
}

const DocumentRequestModal = ({
  closeModal,
  agencyEmployeeId,
  existingDocumentGroupKindSlugs,
}: DocumentRequestModalProps) => {
  const [selectedDocumentKinds, setSelectedDocumentKinds] = React.useState<DocumentGroupKind[]>(
    window.document_group_kinds.filter(
      (documentKind: DocumentGroupKind) =>
        documentKind.default || existingDocumentGroupKindSlugs.includes(documentKind.slug)
    )
  )

  const updateEmployee = useUpdateDocumentGroupsForEmployee(agencyEmployeeId)

  const { mutate: addDocumentGroupRequests } = useAddDocumentGroupRequests(agencyEmployeeId, {
    onSuccess: (newDocumentGroups: DocumentGroup[]) => {
      updateEmployee(newDocumentGroups)
      closeModal()
    },
  })

  return (
    <Modal onRequestClose={closeModal}>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          addDocumentGroupRequests(selectedDocumentKinds.map((documentKind) => documentKind.slug))
        }}
        className="h-full"
      >
        <div className="flex flex-col justify-between h-full">
          <div className="overflow-y-auto px-6 pt-6">
            <div className="h-12 w-12 mb-10 flex items-center justify-center rounded-full border relative">
              <CirclesIllustration className="absolute -top-6 -left-6" />
              <Icon name="edit" />
            </div>
            <Text type="title" className="mb-2">
              {i18next.t('agency.employee_profile.document_section.document_request.title')}
            </Text>
            <Text type="subtext" className="mb-4">
              {i18next.t('agency.employee_profile.document_section.document_request.description')}
            </Text>
            <DocumentGroupKindsSelect
              selectedDocumentKinds={selectedDocumentKinds}
              setSelectedDocumentKinds={setSelectedDocumentKinds}
            />
          </div>
          <Modal.Actions submitText={i18next.t('agency.home.employee_invitation.validate_document_list')} />
        </div>
      </form>
    </Modal>
  )
}

export default DocumentRequestModal
