import classNames from 'classnames'
import React from 'react'

type BackgroundProps = {
  className?: string
}

const Background = ({ className, children }: React.PropsWithChildren<BackgroundProps>) => (
  <div className={classNames('bg-backgroundPrimary h-full w-full py-6 pb-20 px-14', className)}>{children}</div>
)

export default Background
