import React from 'react'
import i18next from 'i18next'
import toast from 'react-hot-toast'
import Background from 'agency/components/Background'
import PageHeader from 'agency/components/PageHeader'
import TradeHeader from 'agency/components/trades/TradeHeader'
import TradeRow from 'agency/components/trades/TradeRow'
import { useSetTradeAsDefault, useAddTrade, useTrades, useUpdateTrade } from 'agency/queries/trades'
import TableList from 'components/TableList'
import Text from 'components/Text'
import Button from 'components/Button'
import Icon from 'components/Icon'
import TradeRequestModal, { SubmitTradePayload } from 'agency/components/trades/TradeRequestModal'
import { Trade } from 'agency/types/trades'

const AgencySettingsShow = () => {
  const { data: trades } = useTrades()
  const [showAddTradeModal, setShowAddTradeModal] = React.useState(false)
  const [tradeToUpdate, setTradeToUpdate] = React.useState<Trade | null>(null)

  const handleAddTrade = () => {
    setShowAddTradeModal(true)
  }

  const closeAddModal = () => {
    setShowAddTradeModal(false)
  }

  const closeUpdateModal = () => setTradeToUpdate(null)

  const onError = (error: Error) => {
    if (error.message === 'NAME_ALREADY_EXISTS') {
      toast.error(i18next.t('agency.settings.trades.error_trade_exists'))
    } else {
      toast.error(i18next.t('common.unknown_error'))
    }
  }

  const { mutate: addTrade } = useAddTrade({
    onSuccess: () => {
      closeAddModal()
    },
    onError,
  })

  const { mutate: updateTrade } = useUpdateTrade({
    onSuccess: () => {
      closeUpdateModal()
    },
    onError,
  })

  const { mutate: setAsDefault } = useSetTradeAsDefault()

  const onChecked = (tradeId: string) => {
    setAsDefault(tradeId)
  }

  const onUpdateClick = (tradeId: string) => {
    setTradeToUpdate(trades.find((trade) => trade.id === tradeId) ?? null)
  }

  const onUpdateSubmit = (tradeId: string) => (trade: SubmitTradePayload) => {
    updateTrade({ tradeId, ...trade })
  }

  const onDeleteClick = () => {}

  if (!trades) return null

  return (
    <div className="h-full w-screen">
      <PageHeader id="employee_settings">
        <Text type="title" className="grow">
          {i18next.t('agency.settings.title')}
        </Text>
        <Button name={i18next.t('agency.settings.trades.add')} onClick={handleAddTrade}>
          <Icon name="add" uiStyle="white" />
          <Text>{i18next.t('agency.settings.trades.add')}</Text>
        </Button>
      </PageHeader>
      <Background>
        <TableList>
          <TradeHeader />
          {trades.map((trade) => (
            <TradeRow
              key={trade.id}
              trade={trade}
              onChecked={onChecked}
              onUpdateClick={onUpdateClick}
              onDeleteClick={onDeleteClick}
            />
          ))}
        </TableList>
      </Background>

      {showAddTradeModal ? <TradeRequestModal onSubmit={addTrade} closeModal={closeAddModal} /> : null}

      {tradeToUpdate ? (
        <TradeRequestModal
          trade={tradeToUpdate}
          onSubmit={onUpdateSubmit(tradeToUpdate.id)}
          closeModal={closeUpdateModal}
        />
      ) : null}
    </div>
  )
}

export default AgencySettingsShow
