import useAgency from 'agency/contexts/AgencyContext'
import IconButton from 'components/IconButton'
import i18next from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type PageHeaderProps = {
  id?: string
}

const PageHeader = ({ id, children }: React.PropsWithChildren<PageHeaderProps>) => {
  const navigate = useNavigate()
  const { currentAgency } = useAgency()

  const onClick = () => {
    if (!currentAgency) return

    navigate(`/agencies/${currentAgency.id}/agency_employees`)
  }

  return (
    <div id={id} className="bg-white py-5 px-14 w-screen items-center">
      <div className="flex items-center gap-4">
        <IconButton name="arrow_back" label={i18next.t('common.go_back')} onClick={onClick} />
        {children}
      </div>
    </div>
  )
}

export default PageHeader
