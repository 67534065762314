import PageHeader from 'agency/components/PageHeader'
import { EmployeeWithContactDetails } from 'agency/types/employees'
import Text from 'components/Text'
import React from 'react'
import { capitalizeFirstLetter } from 'utils/string'

type EmployeeProfileHeaderProps = {
  employee: EmployeeWithContactDetails
}

const EmployeeProfileHeader = ({ employee }: EmployeeProfileHeaderProps) => {
  let employeeInformations = employee.email

  if (employee.phone_number) {
    employeeInformations += ` - ${employee.phone_number}`
  }

  return (
    <PageHeader id="employee_profile_header">
      <Text type="title" className="grow">
        {employee.last_name.toUpperCase()} {capitalizeFirstLetter(employee.first_name)}
      </Text>
      <Text type="subtext">{employeeInformations}</Text>
    </PageHeader>
  )
}

export default EmployeeProfileHeader
