import classNames from 'classnames'
import Button from 'components/Button'
import Divider from 'components/Divider'
import i18next from 'i18next'
import React, { createContext, useMemo } from 'react'
import ReactModal from 'react-modal'

type ModalProps = {
  onRequestClose: () => void
  className?: string
}

type ModalContextType = {
  onClose: () => void
}

const ModalContext = createContext<ModalContextType>({
  onClose: () => {},
})

const Modal = ({ onRequestClose, className, children }: React.PropsWithChildren<ModalProps>) => {
  const modalContextValue = useMemo(() => ({ onClose: onRequestClose }), [onRequestClose])

  return (
    <ReactModal
      isOpen
      onRequestClose={onRequestClose}
      overlayClassName="fixed inset-0 bg-modalBackrop flex justify-center items-center"
      className={classNames('trade-request-modal bg-white rounded-md w-[36rem] max-h-[90%] relative', className)}
    >
      <ModalContext.Provider value={modalContextValue}>{children}</ModalContext.Provider>
    </ReactModal>
  )
}

type ActionProps = {
  submitText: string
  cancelable?: boolean
  onSubmitClick?: () => void
}

const Actions = ({ cancelable = true, submitText, onSubmitClick }: ActionProps) => {
  const { onClose } = React.useContext(ModalContext)

  return (
    <div className="px-6 pb-6">
      <Divider className="my-6" />
      <div className="flex flex-row-reverse justify-between">
        <Button type="submit" name="modal_submit" onClick={onSubmitClick}>
          {submitText}
        </Button>

        {cancelable && (
          <Button type="button" uiStyle="secondary" name="cancel" onClick={onClose}>
            {i18next.t('common.cancel')}
          </Button>
        )}
      </div>
    </div>
  )
}

Modal.Actions = Actions

export default Modal
