import useAgency from 'agency/contexts/AgencyContext'
import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

// To be used for routes with an agencyId param, this component will redirect to the current agency
const CurrentAgencyRedirection = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate()
  const { currentAgency } = useAgency()
  const { agencyId: currentAgencyIdFromUrl } = useParams()

  useEffect(() => {
    if (!currentAgency || currentAgency.id === currentAgencyIdFromUrl) return

    navigate(`/agencies/${currentAgency.id}/agency_employees`)
  }, [currentAgency, currentAgencyIdFromUrl])

  return children ?? <Outlet />
}

export default CurrentAgencyRedirection
