import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import i18next from 'i18next'
import httpClient, { HttpClientError, csrfToken } from 'utils/http-client'
import SkribeLogo from 'components/SkribeLogo'
import Button from 'components/Button'
import Input from 'components/Input'
import Text from 'components/Text'
import PasswordInput from 'components/PasswordInput'
import { getLastAgencyId } from 'agency/contexts/AgencyContext'
import { useURLRedirectPath } from 'utils/redirect'

type LoginFormErrors = {
  not_found: boolean
  invalid_password: boolean
}

const Login = () => {
  const [errors, setErrors] = React.useState<LoginFormErrors>({ not_found: false, invalid_password: false })
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const navigate = useNavigate()
  const URLRedirectPath = useURLRedirectPath()

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    httpClient<{ agency_id: string }>('/session', {
      method: 'post',
      body: { email, password },
    })
      .then(({ agency_id }) => {
        const lastAgencyId = getLastAgencyId()

        navigate(URLRedirectPath ?? `/agencies/${lastAgencyId ?? agency_id}/agency_employees`)
      })
      .catch((error: HttpClientError) => {
        if (error.response?.status === 401) {
          setErrors({ not_found: false, invalid_password: true })
        } else if (error.response?.status === 404) {
          setErrors({ not_found: true, invalid_password: false })
        }
      })
  }

  return (
    <div className="flex h-screen w-screen">
      <div className="w-1/2 mt-12 mb-4 flex flex-col justify-between items-center">
        <div className="w-full flex justify-between px-12">
          <SkribeLogo />
        </div>
        <div className="w-full px-40">
          <Text type="title">{i18next.t('agency.sign_in.title')}</Text>
          <Text type="text">{i18next.t('agency.sign_in.welcome_back')}</Text>
          <form className="mt-8" onSubmit={onSubmit}>
            <input type="hidden" name="authenticity_token" value={csrfToken()} />
            <div className="relative w-full mb-6">
              <Input
                className="w-full"
                name="email"
                label={i18next.t('agency.sign_in.email_label')}
                type="email"
                placeholder={i18next.t('agency.sign_in.email_placeholder')}
                value={email}
                onChange={(newValue) => {
                  setEmail(newValue)
                  setErrors((currentErrors) => ({ ...currentErrors, not_found: false }))
                }}
                error={errors.not_found ? i18next.t('employee.login.not_found') : undefined}
                required
              />
              <PasswordInput
                className="mt-4"
                value={password}
                onChange={(newValue) => {
                  setPassword(newValue)
                  setErrors((currentErrors) => ({ ...currentErrors, invalid_password: false }))
                }}
                error={errors.invalid_password ? i18next.t('employee.login.invalid_password') : undefined}
              />
            </div>
            <Button
              type="submit"
              name={i18next.t('common.log_in')}
              disabled={errors.invalid_password || errors.not_found}
              className="w-full"
            >
              {i18next.t('common.log_in')}
            </Button>
          </form>
          <div className="flex justify-center mt-6">
            <Link to={{ pathname: '/agent/password/new', search: email ? `?email=${email}` : '' }}>
              <Text uiStyle="bold" className="text-textAccent">
                {i18next.t('agency.sign_in.first_sign_in_or_forgot_password')}
              </Text>
            </Link>
          </div>
        </div>
        <div className="">{i18next.t('agency.sign_in.copyright')}</div>
      </div>
      <div className="flex-shrink-0 bg-primary w-1/2 h-full flex place-items-center justify-center">
        <SkribeLogo iconFillColor="#fff" textFillColor="#fff" size="large" />
      </div>
    </div>
  )
}

export default Login
