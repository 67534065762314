import React, { Dispatch, SetStateAction } from 'react'
import i18next from 'i18next'
import toast from 'react-hot-toast'

import { isEmailValid } from 'utils/validators'
import Icon from 'components/Icon'
import Input from 'components/Input'
import Text from 'components/Text'
import { EmployeeWithInvitationStatus, EmployeeWithContactDetails } from 'agency/types/employees'
import useAgency from 'agency/contexts/AgencyContext'
import { useAddEmployee } from 'agency/queries/employees'
import CirclesIllustration from 'agency/components/CirclesIllustration'
import Modal from 'agency/components/Modal'

const PHONE_NUMBER_ERROR = 'PHONE_NUMBER'
const ALREADY_INVITED_ERROR = 'ALREADY_INVITED'

type InviteProps = {
  setInvitedEmployee: Dispatch<SetStateAction<EmployeeWithContactDetails | undefined>>
  onNext: () => void
}

const Invite = ({ onNext, setInvitedEmployee }: InviteProps) => {
  const { currentAgency, agencies } = useAgency()
  const [errors, setErrors] = React.useState<{ email?: string; phone_number?: string }>({})
  const { mutate: addEmployee } = useAddEmployee({
    onSuccess: (employee: EmployeeWithInvitationStatus, { email }: { email: string }) => {
      setInvitedEmployee({ ...employee, email })
      onNext()
    },
    onError: (error) => {
      if (error.message === ALREADY_INVITED_ERROR) {
        toast.error(i18next.t('agency.home.employee_invitation.already_invited'))
        setErrors({ email: i18next.t('agency.home.employee_invitation.already_invited') })
      } else if (error.message === PHONE_NUMBER_ERROR) {
        toast.error(i18next.t('agency.home.employee_invitation.phone_number_error'))
        setErrors({ phone_number: i18next.t('agency.home.employee_invitation.phone_number_error') })
      } else {
        toast.error(i18next.t('agency.home.employee_invitation.unknown_error'))
      }
    },
  })

  const resetError = (errorKey: 'phone_number' | 'email') => () =>
    setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: undefined }))

  const showAgencyName = agencies.length > 1 && currentAgency

  return (
    <div className="p-6">
      <CirclesIllustration className="absolute top-0 left-0" />
      <div className="flex flex-row justify-between">
        <div className="border rounded-full p-3 border-borderMedium h-12 w-12 mb-6">
          <Icon name="person_add" />
        </div>
        {showAgencyName && <Text type="subtitle">{currentAgency.name}</Text>}
      </div>
      <Text type="title">{i18next.t('agency.home.employee_invitation.title')}</Text>
      <Text type="subtext">{i18next.t('agency.home.employee_invitation.description')}</Text>
      <form
        onSubmit={(event) => {
          event.preventDefault()

          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { email, first_name, last_name, phone_number } = event.target as typeof event.target & {
            email: { value: string }
            first_name: { value: string }
            last_name: { value: string }
            phone_number: { value: string }
          }

          if (!isEmailValid(email.value)) {
            setErrors({ email: i18next.t('common.errors.invalid_email') })
            return
          }

          addEmployee({
            email: email.value,
            firstName: first_name.value,
            lastName: last_name.value,
            phoneNumber: phone_number.value,
          })
        }}
      >
        <div className="flex gap-4 mt-6 mb-4 justify-between">
          <Input className="w-1/2" name="first_name" label={i18next.t('common.first_name')} type="text" required />
          <Input className="w-1/2" name="last_name" label={i18next.t('common.last_name')} type="text" required />
        </div>
        <div className="flex gap-4 justify-between">
          <Input
            className="w-1/2"
            name="email"
            label={i18next.t('common.email')}
            type="email"
            required
            onChange={resetError('email')}
            error={errors.email}
          />
          <Input
            className="w-1/2"
            name="phone_number"
            label={i18next.t('common.phone_number')}
            type="tel"
            onChange={resetError('phone_number')}
            error={errors.phone_number}
          />
        </div>
        <Modal.Actions submitText={i18next.t('agency.home.employee_invitation.send')} />
      </form>
    </div>
  )
}

export default Invite
