import React from 'react'
import { useLocation } from 'react-router-dom'

import { useEmployees } from 'agency/queries/employees'
import { useAgent, useAgents } from 'agency/queries/agents'
import { RecordStatus, isRecordStatus } from 'agency/types/employees'
import EmployeeListHeader from 'agency/components/agency_employees/AgencyEmployeeIndex/EmployeeListHeader'
import EmptyEmployeeList from 'agency/components/agency_employees/AgencyEmployeeIndex/EmptyEmployeeList'
import EmployeeListTable from 'agency/components/agency_employees/AgencyEmployeeIndex/EmployeeListTable'
import EmployeeInvitationModal from 'agency/components/EmployeeInvitationModal'
import { addErrorInfo, sendError } from 'utils/appsignal'

const EmployeesList = () => {
  const { data: agent } = useAgent()
  const { hash } = useLocation()

  const [openInvitationModal, setOpenInvitationModal] = React.useState(false)

  const tabFromUrl = hash.replace('#', '')
  const [currentTab, setCurrentTab] = React.useState<RecordStatus>(isRecordStatus(tabFromUrl) ? tabFromUrl : 'active')

  const { data: employees } = useEmployees()
  const { data: agents } = useAgents()

  if (!agent || !employees) return null

  if (!Array.isArray(employees)) {
    addErrorInfo('employees', employees)
    sendError(new Error(`Employees is of type ${typeof employees}`))
    return null
  }

  const filteredEmployees = employees.filter((employee) => employee.status === currentTab)

  return (
    <>
      {openInvitationModal ? <EmployeeInvitationModal closeModal={() => setOpenInvitationModal(false)} /> : null}
      <EmployeeListHeader
        employees={employees}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        setOpenInvitationModal={setOpenInvitationModal}
      />

      {employees.length > 0 ? (
        <EmployeeListTable employees={filteredEmployees} agents={agents} currentTab={currentTab} />
      ) : (
        <EmptyEmployeeList setOpenInvitationModal={setOpenInvitationModal} />
      )}
    </>
  )
}

export default EmployeesList
