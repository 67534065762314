import { Trade } from 'agency/types/trades'
import CheckBox from 'components/CheckBox'
import IconButton from 'components/IconButton'
import TableList from 'components/TableList'
import Text from 'components/Text'
import i18next from 'i18next'
import React from 'react'

type TradeRowProps = {
  trade: Trade
  onChecked: (tradeId: string) => void
  onUpdateClick: (tradeId: string) => void
  onDeleteClick: (tradeId: string) => void
}

const TradeRow = ({ trade, onChecked, onUpdateClick, onDeleteClick }: TradeRowProps) => {
  const label = trade.isDefault
    ? i18next.t('agency.settings.trades.default')
    : i18next.t('agency.settings.trades.not_default')

  return (
    <TableList.Row key={trade.id} className="cursor-pointer" id={`trade-${trade.id}`}>
      <Text>{trade.name}</Text>
      <CheckBox
        size="small"
        name={label}
        checked={trade.isDefault}
        label={label}
        onChange={(checked) => {
          if (checked) {
            onChecked(trade.id)
          }
        }}
      />
      <Text>{trade.documentGroupsConfigs.map((config) => config.label).join(', ')}</Text>
      <div className="flex flex-row gap-2">
        <IconButton
          label={i18next.t('agency.settings.trades.edit_button', { tradeName: trade.name })}
          name="edit"
          uiStyle="accent"
          onClick={() => onUpdateClick(trade.id)}
        />
        {!trade.isDefault && (
          <IconButton
            label={i18next.t('agency.settings.trades.delete_button', { tradeName: trade.name })}
            name="delete"
            uiStyle="error"
            onClick={() => onDeleteClick(trade.id)}
          />
        )}
      </div>
    </TableList.Row>
  )
}

export default TradeRow
